// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/prop-types */
import React, { forwardRef } from 'react';
import { Routes, Route } from 'react-router-dom';
import Wrapper from '../layout/Wrapper/Wrapper';
import Aside from '../layout/Aside/Aside';
import CheckoutContainer from '../components/checkout/CheckoutContainer';
import MobileLoading from '../components/loading/MobileLoading';
import WebLoading from '../components/loading/WebLoading';

const AppRouter = forwardRef(
	(
		{
			fullScreenStatus,
			vehicles,
			uniqueAccessories,
			accessoryCounts,
			checkoutCart,
			stateData,
			selectedDeliveryOption,
			selectedPaymentOption,
			stacked,
			oemVehicles,
			withOutAsidePages,
			vehicleConfig,
			currentVehicleConfig,
			oemProfileProps,
		},
		ref,
	) => {
		// Function to render the appropriate content based on conditions
		const renderContent = () => {
			// First scenario - Everything is loaded
			if (
				oemProfileProps !== null &&
				vehicles.length &&
				uniqueAccessories &&
				accessoryCounts &&
				checkoutCart &&
				stateData &&
				selectedDeliveryOption &&
				selectedPaymentOption
			) {
				return (
					<>
						<Routes>
							{withOutAsidePages.map((path) => (
								<Route key={path} path={path} />
							))}
							<Route path='*' element={<Aside />} />
						</Routes>
						<Wrapper />
					</>
				);
			}

			if (
				currentVehicleConfig &&
				vehicleConfig &&
				oemVehicles &&
				stateData &&
				oemProfileProps !== null &&
				vehicles.length
			) {
				return (
					<>
						<Routes>
							{withOutAsidePages.map((path) => (
								<Route key={path} path={path} />
							))}
							<Route path='*' element={<Aside />} />
						</Routes>
						<Wrapper />
					</>
				);
			}
			if (stacked) {
				// Second scenario - Mobile loading
				return (
					<CheckoutContainer>
						<MobileLoading />
					</CheckoutContainer>
				);
			}

			// Third scenario - Web loading
			return (
				<CheckoutContainer>
					<WebLoading />
				</CheckoutContainer>
			);

			// Add more scenarios by adding more if conditions here
			// Example:
			// if (someErrorCondition) {
			//   return (
			//     <CheckoutContainer>
			//       <ErrorState />
			//     </CheckoutContainer>
			//   );
			// }
		};

		return (
			<div
				ref={ref}
				className='app'
				style={{
					backgroundColor: fullScreenStatus && 'var(--bs-body-bg)',
					zIndex: fullScreenStatus && 1,
					overflow: fullScreenStatus && 'scroll',
				}}>
				{renderContent()}
			</div>
		);
	},
);

export default AppRouter;
