// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { getToken } from 'firebase/app-check';
import { OEM_KEY_TO_PROJECT_MAP } from '@ekhodealer/ekho-common';
import { encryptData, dateFormatData } from '@ekhodealer/ekho-common/utils';
import { getDistance } from 'geolib';

// eslint-disable-next-line import/no-cycle
import { auth, appCheck } from './firebase';
import { ENV, PREQUAL_OUTCOMES } from './common/data/constants';

// helper function for setting function host.
function setFunctionHost() {
	switch (process.env.REACT_APP_CURR_ENV) {
		case ENV.DEVELOPMENT:
			// return 'https://us-central1-airdealer-67669.cloudfunctions.net';
			return 'http://localhost:5000/airdealer-67669/us-central1';
		case ENV.STAGING:
			return 'https://us-central1-ed-staging.cloudfunctions.net';
		case ENV.PRODUCTION:
			return 'https://us-central1-ad-backend-prod.cloudfunctions.net';
		default:
			return 'http://localhost:5000/airdealer-67669/us-central1';
	}
}
const FUNCTION_HOST = setFunctionHost();

// Helper function for getting the AppCheck token
async function getAppCheckToken() {
	try {
		const appCheckToken = await getToken(appCheck, false);
		return appCheckToken;
	} catch (error) {
		throw new Error('Failed to get AppCheck token: ', error);
	}
}

// function that returns the correct headers for a request
function getRequestHeaders(appCheckToken, authToken) {
	// Add app check token to request headers
	const functionHeaders = { 'X-Firebase-AppCheck': appCheckToken.token };

	if (authToken) {
		functionHeaders.Authorization = `Bearer ${authToken}`;
	}
	return functionHeaders;
}

export async function addPlacedOrderIdToCheckoutCart(placedOrderId, checkoutCartId) {
	const body = {
		oemKey: process.env.REACT_APP_OEM_KEY,
		placedOrderId,
		checkoutCartId,
	};
	try {
		const url = `${FUNCTION_HOST}/checkoutCart_oncreate_v2/addPlacedOrderId`;
		const appCheckToken = await getAppCheckToken();
		const response = await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken),
		});
		if (response.status !== 200) {
			throw new Error(`Error adding placed order id to checkout cart. Response: ${response}`);
		}
		return response;
	} catch (e) {
		throw new Error(`Error adding placed order id to checkout cart: ${e}`);
	}
}

export const createPlacedOrderObj = async (placedOrderReqBody) => {
	try {
		// changing to unauthenticated call
		// const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/placedOrder_oncreate_v2`;
		const appCheckToken = await getAppCheckToken();
		const response = await axios.post(url, placedOrderReqBody, {
			headers: getRequestHeaders(appCheckToken),
		});
		return response.data;
	} catch (e) {
		throw new Error(`Error placing order: ${e}`);
	}
};

// create a function that gets the vehicles from an order using the purchasedVehicle_onfetch/vehiclesFromPlacedOrder/:placedOrderId
export const getVehiclesFromPlacedOrder = async (placedOrderId, buyerId) => {
	try {
		// remove authentication so that this can be called by buyers who are not logged in
		// const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/purchasedVehicle_onfetch_v2/vehiclesFromPlacedOrder/${placedOrderId}`;
		const appCheckToken = await getAppCheckToken();
		const response = await axios.get(url, {
			headers: getRequestHeaders(appCheckToken),
			params: { oemKey: process.env.REACT_APP_OEM_KEY, buyerId },
		});
		return response.data;
	} catch (e) {
		throw new Error(`Error fetching vehicles from placed order: ${e}`);
	}
};

export const getCurrentAdditionalDues = async (purchasedVehicleId) => {
	try {
		const url = `${FUNCTION_HOST}/purchasedVehicle_onfetch_v2/currentAdditionalDues/${purchasedVehicleId}`;
		const appCheckToken = await getAppCheckToken();
		const response = await axios.get(url, {
			headers: getRequestHeaders(appCheckToken),
			params: {
				oemKey: process.env.REACT_APP_OEM_KEY,
				isForDocuments: false,
			},
		});
		return response.data;
	} catch (e) {
		throw new Error(`Error fetching additional dues: ${e}`);
	}
};

export const createPreorderObjs = async (preorders, paymentIntentId) => {
	try {
		const body = {
			oemKey: process.env.REACT_APP_OEM_KEY,
			preorders,
			paymentIntentId,
		};
		const url = `${FUNCTION_HOST}/placedOrder_oncreate_v2/preorder`;
		const appCheckToken = await getAppCheckToken();
		const response = await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken),
		});
		return response.data;
	} catch (e) {
		throw new Error(`Error creating preorders: ${e}`);
	}
};

export const updatePaymentIntentAmountFixed = async (
	paymentIntentId,
	dbObj,
	dbObjIds,
	amount,
	paymentDataPath,
	processingFeeInc,
	processingFeeIncAmount,
) => {
	const body = {
		oemKey: process.env.REACT_APP_OEM_KEY,
		paymentIntentId,
		dbObj,
		dbObjIds,
		amount,
		paymentDataPath,
		processingFeeInc,
		processingFeeIncAmount,
	};
	try {
		// make this call unauthenticated so that it can be called by buyers who are not logged in
		// const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/stripePayment_v2/updatePaymentIntentAmount`;
		const appCheckToken = await getAppCheckToken();
		await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken),
		});
	} catch (e) {
		throw new Error(`Error updating payment intent amount: ${e}`);
	}
};

export const checkBuyerWithEmailExists = async (email) => {
	const body = {
		oemKey: process.env.REACT_APP_OEM_KEY,
		email,
	};
	try {
		const url = `${FUNCTION_HOST}/buyer_onfetch_v2/checkBuyerWithEmailExists`;
		const appCheckToken = await getAppCheckToken();
		const response = await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken),
		});
		return response.data;
	} catch (e) {
		throw new Error(`Error checking if buyer object with email exists: ${e}`);
	}
};

export const getUnauthenticatedOemData = async (oemId) => {
	try {
		const url = `${FUNCTION_HOST}/oem_onfetch_v2/${oemId}`;
		// console.log(process.env.REACT_APP_OEM_KEY);
		const appCheckToken = await getAppCheckToken();
		const response = await axios.get(url, {
			headers: getRequestHeaders(appCheckToken),
			params: { oemKey: process.env.REACT_APP_OEM_KEY, excludeVehicles: true },
		});
		return response.data;
	} catch (e) {
		// console.log('THROWN ERROR WHEN FETCHING OEM: ', e);
		throw new Error(`Error fetching unauthenticated oem data: ${e}`);
	}
};

export const getOemVehicles = async (oemId, vehicleProductIds) => {
	try {
		const url = `${FUNCTION_HOST}/oem_onfetch_v2/vehicles/${oemId}`;
		const appCheckToken = await getAppCheckToken();
		const response = await axios.get(url, {
			headers: getRequestHeaders(appCheckToken),
			params: {
				oemKey: process.env.REACT_APP_OEM_KEY,
				brandKey: process.env.REACT_APP_BRAND_KEY,
				productIds: vehicleProductIds.join(','),
			},
		});
		return response.data;
	} catch (e) {
		throw new Error(`Error fetching oem vehicles: ${e}`);
	}
};

export const getOemPickUpLocations = async (oemId) => {
	try {
		const url = `${FUNCTION_HOST}/oem_onfetch_v2/pickUpLocations/${oemId}`;
		const appCheckToken = await getAppCheckToken();
		const response = await axios.get(url, {
			headers: getRequestHeaders(appCheckToken),
			params: { oemKey: process.env.REACT_APP_OEM_KEY },
		});
		return response.data;
	} catch (e) {
		throw new Error(`Error fetching oem pick up locations: ${e}`);
	}
};

export const getBuyerData = async () => {
	try {
		const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/buyer_onfetch_v2`;
		const appCheckToken = await getAppCheckToken();
		const response = await axios.get(url, {
			headers: getRequestHeaders(appCheckToken, token),
			params: { oemKey: process.env.REACT_APP_OEM_KEY },
		});
		return response.data;
	} catch (e) {
		throw new Error(`Error fetching buyer: ${e}`);
	}
};

export const createCheckoutPaymentIntent = async (
	paymentDescription,
	amountToPay,
	paymentType,
	checkoutCartId,
	oemId,
	processingFeeInc = false,
	processingFeeIncAmount = 0,
	paymentMethodTypes = ['card'],
) => {
	// Create Stripe payment intent
	const body = {
		oemKey: process.env.REACT_APP_OEM_KEY,
		brandKey: process.env.REACT_APP_BRAND_KEY,
		oemId,
		amountToPay,
		paymentMethodTypes,
		paymentDescription,
		processingFeeInc,
		processingFeeIncAmount,
		paymentType,
		checkoutCartId,
	};
	try {
		const url = `${FUNCTION_HOST}/stripePayment_v2/createCheckoutPaymentIntent`;
		const appCheckToken = await getAppCheckToken();
		const response = await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken),
		});
		return response.data;
	} catch (e) {
		throw new Error(`Error creating payment intent associated with a placed order: ${e}`);
	}
};

export const fetchPaymentIntent = async (paymentIntentId) => {
	try {
		// const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/stripePayment_v2/fetchPaymentIntent`;
		const appCheckToken = await getAppCheckToken();
		const response = await axios.get(url, {
			headers: getRequestHeaders(appCheckToken),
			params: { oemKey: process.env.REACT_APP_OEM_KEY, paymentIntentId },
		});
		return response.data;
	} catch (e) {
		throw new Error(`Error fetching payment intent: ${e}`);
	}
};

//   - logName: string containing log name. Naming convention: separate words with hyphens
//   - logMessage: string containing log message
//   - severity: severity of the log -> enum: INFO, WARNING, ERROR, CRITICAL
//   - labels: object containing custom labels -> userId, oemKey, etc)
// NOTE: always call this function without await. We don't want to block on logging.
export async function createLog(logName, logMessage, severity, labels) {
	const projectId = OEM_KEY_TO_PROJECT_MAP[process.env.REACT_APP_OEM_KEY];
	if (!projectId) {
		throw new Error(`Invalid environment: ${process.env.REACT_APP_OEM_KEY}`);
	}
	const body = {
		oemKey: process.env.REACT_APP_OEM_KEY,
		logName,
		logMessage,
		siteName: window.location.hostname.replace('.ekhodealer.com', ''),
		projectId,
		severity,
		labels: { ...labels, oemKey: process.env.REACT_APP_OEM_KEY },
	};
	try {
		const appCheckToken = await getAppCheckToken();
		// if calling from an authenticated environment
		if (auth && auth.currentUser) {
			const token = await auth.currentUser.getIdToken();
			const url = `${FUNCTION_HOST}/log_oncreate_v2`;
			await axios.post(url, body, {
				headers: getRequestHeaders(appCheckToken, token),
			});
		} else {
			const url = `${FUNCTION_HOST}/log_oncreate_v2/unauthenticated`;
			await axios.post(url, body, {
				headers: getRequestHeaders(appCheckToken),
			});
		}
	} catch (e) {
		// Do not block if fail to create logs
		throw new Error(`Error creating log: ${e}`);
	}
}

export async function saveReEngagementLinkClick(reid) {
	const body = {
		oemKey: process.env.REACT_APP_OEM_KEY,
		reid,
	};
	try {
		const url = `${FUNCTION_HOST}/checkoutCart_oncreate_v2/saveReEngagementLinkClick`;
		const appCheckToken = await getAppCheckToken();
		await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken),
		});
	} catch (e) {
		throw new Error(`Error saving re-engagement link click: ${e}`);
	}
}

export const checkoutCartWasOpened = async (checkoutCartId) => {
	const body = {
		oemKey: process.env.REACT_APP_OEM_KEY,
		checkoutCartId,
	};
	try {
		const url = `${FUNCTION_HOST}/checkoutCart_oncreate_v2/checkoutCartWasOpened`;
		const appCheckToken = await getAppCheckToken();
		await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken),
		});
	} catch (e) {
		throw new Error(`Error marking checkout cart as opened: ${e}`);
	}
};

export const getCheckoutCart = async (checkoutCartId) => {
	try {
		const url = `${FUNCTION_HOST}/checkoutCart_onfetch_v2`;
		const appCheckToken = await getAppCheckToken();
		const response = await axios.get(url, {
			headers: getRequestHeaders(appCheckToken),
			params: { oemKey: process.env.REACT_APP_OEM_KEY, checkoutCartId },
		});
		const {
			id,
			cancelUrl,
			nativeDefaultCheckoutUrl,
			ecommUserId,
			ecommOrderId,
			oemKey,
			registrationState,
			vehicles,
			accessories,
			dateCreated,
			orderCreationType,
			isDisabled = false,
			linkUseType = 'MULTI_USE',
			customLinkConfig = {},
			discounts = [],
			looseAccessories,
			isConfigurable = false,
			preorderProdStart,
		} = response.data;
		// initialize a uniqueAccessoriesMap and initAccessoryCounts map
		const uniqueAccessoriesMap = new Map();
		const initAccessoryCounts = new Map();
		// loop through accessories and add to uniqueAccessoriesMap and initAccessoryCounts map

		// if canon
		if (vehicles[0]?.accessories) {
			vehicles.forEach((vehicle) => {
				const { accessories: vehicleAccessories } = vehicle;
				vehicleAccessories.forEach((acc) => {
					const accId = acc.productId;
					acc.id = accId;
					uniqueAccessoriesMap.set(accId, acc);
					initAccessoryCounts.set(accId, 1);
				});
			});
			return {
				id,
				cancelUrl,
				nativeDefaultCheckoutUrl,
				ecommUserId,
				ecommOrderId,
				oemKey,
				registrationState,
				vehicles,
				uniqueAccessoriesMap,
				initAccessoryCounts,
				dateCreated,
				orderCreationType,
				isDisabled,
				linkUseType,
				customLinkConfig,
				looseAccessories,
				discounts,
				isConfigurable,
				preorderProdStart,
			};
		}

		// else if old
		let hasBoltOns = false;
		// loop through accessories and add to uniqueAccessoriesMap and initAccessoryCounts map
		accessories.forEach((accessory) => {
			uniqueAccessoriesMap.set(accessory.id, accessory);
			initAccessoryCounts.set(accessory.id, accessory.count);
			// if accessory.boltOnVehicleIds exists, set hasBoltOns to true
			if (accessory.boltOnVehicleIds) {
				hasBoltOns = true;
			}
		});
		return {
			id,
			cancelUrl,
			nativeDefaultCheckoutUrl,
			ecommUserId,
			ecommOrderId,
			oemKey,
			registrationState,
			vehicles,
			uniqueAccessoriesMap,
			initAccessoryCounts,
			dateCreated,
			orderCreationType,
			hasBoltOns,
			isDisabled,
			linkUseType,
			customLinkConfig,
			discounts,
			isConfigurable,
			preorderProdStart,
		};
	} catch (e) {
		throw new Error(`Error fetching checkout cart: ${e}`);
	}
};

export const fetchGoogleMapsApiKey = async () => {
	try {
		// const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/secrets_onfetch_v2/fetchGoogleMapsApiKey`;
		const appCheckToken = await getAppCheckToken();
		const response = await axios.get(url, {
			// headers: getRequestHeaders(appCheckToken, token),
			headers: getRequestHeaders(appCheckToken),
			params: { oemKey: process.env.REACT_APP_OEM_KEY },
		});
		return response.data;
	} catch (e) {
		throw new Error(`Error fetching google maps API key: ${e}`);
	}
};

export const getPreQualDecision = async (buyerData) => {
	const body = {
		oemKey: process.env.REACT_APP_OEM_KEY,
		buyerData,
	};
	try {
		const url = `${FUNCTION_HOST}/prequal_oncreate_v2/`;
		const appCheckToken = await getAppCheckToken();
		const response = await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken),
		});
		return response.data;
	} catch (e) {
		return {
			decision: PREQUAL_OUTCOMES.DENIED,
			rejectionReasons: ['Error getting prequal data'],
		};
	}
};

export const getStateTaxAndTARData = async () => {
	try {
		const url = `${FUNCTION_HOST}/state_onfetch_v2/allStates/taxAndTARSupportData`;
		const appCheckToken = await getAppCheckToken();
		const response = await axios.get(url, {
			headers: getRequestHeaders(appCheckToken),
			params: { oemKey: process.env.REACT_APP_OEM_KEY },
		});
		return response.data;
	} catch (e) {
		throw new Error(`Error fetching state taxes and fees: ${e}`);
	}
};

export const createAbandonedCart = async (
	checkoutCartId,
	buyerEmail,
	setAbandonedCartId,
	setCreatingAbandonedCart,
) => {
	const body = {
		oemKey: process.env.REACT_APP_OEM_KEY,
		checkoutCartId,
		buyerEmail,
	};
	try {
		const url = `${FUNCTION_HOST}/checkoutCart_oncreate_v2/createAbandonedCart`;
		const appCheckToken = await getAppCheckToken();
		const response = await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken),
		});
		if (response.status !== 200) {
			throw new Error(`Error creating abandoned cart. Response: ${response}`);
		}
		// only return the abandonedCartId if it exists
		if (response.data.abandonedCartId) {
			const newAbandonedCartId = response.data.abandonedCartId;
			setAbandonedCartId(newAbandonedCartId);
			setCreatingAbandonedCart(false);
			return response.data.abandonedCartId;
		}
		throw new Error('Error creating abandoned cart. No abandonedCartId returned');
	} catch (e) {
		throw new Error(`Error creating abandoned cart: ${e}`);
	}
};

export const updateAbandonedCartServerCall = async (abandonedCartId, updateData) => {
	const body = {
		oemKey: process.env.REACT_APP_OEM_KEY,
		abandonedCartId,
		updateData,
	};
	try {
		const url = `${FUNCTION_HOST}/checkoutCart_oncreate_v2/updateAbandonedCart`;
		const appCheckToken = await getAppCheckToken();
		const response = await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken),
		});
		if (response.status !== 200) {
			throw new Error(`Error updating abandoned cart. Response: ${response}`);
		}
		return response;
	} catch (e) {
		throw new Error(`Error updating abandoned cart: ${e}`);
	}
};

export const saveCreditApplication = async (
	vehiclePurchasedId,
	creditApplication,
	submitApp = true,
) => {
	try {
		// const token = await auth.currentUser.getIdToken();
		const url = `${FUNCTION_HOST}/purchasedVehicle_oncreate_v2/${vehiclePurchasedId}/creditApplication`;
		// Encrypt the applicant credit application data
		const dateFormattedApplicationDetails = dateFormatData(creditApplication.applicantDetails);
		const encryptedApplicantDetails = await encryptData(
			dateFormattedApplicationDetails,
			process.env.REACT_APP_ENCRYPTION_KEY,
		);
		const dateFormattedEmploymentDetails = dateFormatData(creditApplication.employmentDetails);
		const encryptedEmploymentDetails = await encryptData(
			dateFormattedEmploymentDetails,
			process.env.REACT_APP_ENCRYPTION_KEY,
		);
		creditApplication.applicantDetails = encryptedApplicantDetails;
		creditApplication.employmentDetails = encryptedEmploymentDetails;
		const body = { creditApplication, oemKey: process.env.REACT_APP_OEM_KEY, submitApp };
		const appCheckToken = await getAppCheckToken();
		await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken),
		});
	} catch (e) {
		throw new Error(`Error saving credit application: ${e}`);
	}
};

export async function updateFinancingActionItem(
	purchasedVehicleId,
	updatedActionItem,
	daysUntilSubmission = null,
) {
	const body = {
		oemKey: process.env.REACT_APP_OEM_KEY,
		updatedActionItem,
		daysUntilSubmission,
	};
	try {
		const url = `${FUNCTION_HOST}/purchasedVehicle_oncreate_v2/${purchasedVehicleId}/financingActionItem`;
		const appCheckToken = await getAppCheckToken();
		await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken),
		});
	} catch (e) {
		throw new Error(`Error updated financing action item: ${e}`);
	}
}

function geocodeAddressClientSide(address) {
	return new Promise((resolve, reject) => {
		const geocoder = new window.google.maps.Geocoder();
		geocoder.geocode({ address }, (results, status) => {
			if (status === 'OK') {
				const latitude = results[0].geometry.location.lat();
				const longitude = results[0].geometry.location.lng();
				resolve({ latitude, longitude });
			} else {
				reject(new Error(`Geocoding failed: ${status}`));
			}
		});
	});
}

async function getDistanceMatrixWithClientLibrary(origin, destination) {
	return new Promise((resolve, reject) => {
		const service = new window.google.maps.DistanceMatrixService();
		service.getDistanceMatrix(
			{
				origins: [origin],
				destinations: [destination],
				travelMode: 'DRIVING',
				unitSystem: window.google.maps.UnitSystem.IMPERIAL,
			},
			async (response, status) => {
				// Odd double status format from the Google Maps API
				if (status === 'OK') {
					const result = response.rows[0].elements[0];
					if (result.status === 'OK') {
						const distanceInMiles = parseFloat(
							(result.distance.value / 1609.34).toFixed(2),
						);
						const simplifiedResponse = {
							distance: distanceInMiles,
							duration: result.duration.text,
						};
						resolve(simplifiedResponse);
					} else if (result.status === 'ZERO_RESULTS') {
						try {
							// Fallback to geocoding and Haversine distance calculation
							const originCoords = await geocodeAddressClientSide(origin);
							const destinationCoords = await geocodeAddressClientSide(destination);
							const distanceInMeters = getDistance(originCoords, destinationCoords);
							const distanceInMiles = parseFloat(
								(distanceInMeters / 1609.34).toFixed(2),
							);
							const simplifiedResponse = {
								distance: distanceInMiles,
								duration: 'N/A', // Duration is not available with Haversine calculation
							};
							resolve(simplifiedResponse);
						} catch (error) {
							reject(new Error(`Geocoding fallback failed: ${error.message}`));
						}
					} else {
						reject(new Error(`Distance Matrix request failed: ${status}`));
					}
				} else {
					reject(new Error(`Distance Matrix request failed: ${status}`));
				}
			},
		);
	});
}

export async function getDistanceBetweenAddressesAsync(originAddress, destinationAddress) {
	try {
		const response = await getDistanceMatrixWithClientLibrary(
			originAddress,
			destinationAddress,
		);
		return response;
	} catch (error) {
		throw new Error(`Error getting distance between addresses: ${error}`);
	}
}

export async function sendTransactionalEmail(messageData, messageId) {
	const body = {
		oemKey: process.env.REACT_APP_OEM_KEY,
		messageData,
		messageId,
	};
	try {
		const url = `${FUNCTION_HOST}/send_transactional_email_v2/sendTransactionalEmail`;
		const appCheckToken = await getAppCheckToken();
		const response = await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken),
		});
		if (response.status !== 200) {
			throw new Error(`Error sending transactional email. Response: ${response}`);
		}
		return response;
	} catch (e) {
		throw new Error(`Error sending transactional email: ${e}`);
	}
}

export const getVehicleConfig = async (productId) => {
	try {
		const url = `${FUNCTION_HOST}/vehicleConfig_onfetch_v2`;
		const appCheckToken = await getAppCheckToken();
		const response = await axios.get(url, {
			headers: getRequestHeaders(appCheckToken),
			params: {
				oemKey: process.env.REACT_APP_OEM_KEY,
				brandKey: process.env.REACT_APP_BRAND_KEY,
				productId,
			},
		});
		const { vehicleConfig, defaultVehicleConfig } = response.data;

		return {
			vehicleConfigData: vehicleConfig,
			defaultVehicleConfigData: defaultVehicleConfig,
		};
	} catch (e) {
		throw new Error(`Error fetching vehicle config: ${e}`);
	}
};

export const createCheckoutCart = async (body) => {
	try {
		const url = `${FUNCTION_HOST}/checkoutCart_oncreate_v2/`;
		const appCheckToken = await getAppCheckToken();
		const response = await axios.post(url, body, {
			headers: getRequestHeaders(appCheckToken),
			params: { oemKey: process.env.REACT_APP_OEM_KEY },
		});
		const { ekhoCheckoutCartId } = response.data;

		return { cartId: ekhoCheckoutCartId };
	} catch (e) {
		throw new Error(`Error fetching vehicle config: ${e}`);
	}
};
