// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useMemo, useState, useLayoutEffect, useRef } from 'react';
import { ThemeProvider } from 'react-jss';
import { ReactNotifications } from 'react-notifications-component';
import { useFullscreen } from 'react-use';
import {
	oemMap,
	ORDER_CREATION_TYPES,
	PAYMENT_TYPES,
	OEM_KEY_TO_HOTJAR_SITE_ID,
} from '@ekhodealer/ekho-common';
import { getOemId, getPaymentConfigOptions } from '@ekhodealer/ekho-common/utils';
import { TourProvider } from '@reactour/tour';
import Hotjar from '@hotjar/browser';

import { onAuthStateChanged } from 'firebase/auth';
import { ThemeContext, Portal, useDarkMode, getOS } from '@ekhodealer/ekho-common/components';
import ContextWrapper from '../contexts/contextWrapper';
import steps, { styles } from '../steps';
import {
	COLORS,
	STACKING_BREAKPOINT,
	DELIVERY_CHOICES,
	PAYMENT_CHOICES,
	STRIPE_PAYMENT_METHOD_TYPES,
	PAYMENT_DESCRIPTION_TYPES,
	ENV,
	MODULES,
} from '../common/data/constants';
import { dashboardMenu, demoPages, layoutMenu } from '../menu';
// eslint-disable-next-line camelcase
import { track_checkout_buyer_abandoned_cart } from '../Utils/analyticsFuncs';
import { auth } from '../firebase';
import {
	getUnauthenticatedOemData,
	// createDueTodayPaymentIntent,
	createCheckoutPaymentIntent,
	getCheckoutCart,
	createLog,
	getOemVehicles,
	getOemPickUpLocations,
	checkoutCartWasOpened,
	getStateTaxAndTARData,
	fetchGoogleMapsApiKey,
	getVehicleConfig,
} from '../serverCalls';
import {
	debounceSimple,
	getVariantOptionsFromVehicles,
	isPurchasePreorder,
} from '../Utils/util_funcs';
import AppRouter from './AppRouter';

const App = () => {
	getOS();

	// ================ DEFINE CONTEXTS ================ //

	// Active Module Context
	const [activeModule, setActiveModule] = useState(null);
	const activeModuleValue = useMemo(() => ({ activeModule, setActiveModule }), [activeModule]);

	// Fetch Checkout Cart Context
	const [isLoading, setIsLoading] = useState(false);
	const fetchCheckoutValue = useMemo(
		() => ({ fetchCheckoutData, isLoading }),
		[isLoading], // add any other dependencies if fetchCheckoutData uses any other state
	);

	// Vehicle Config Context
	const [vehicleConfig, setVehicleConfig] = useState(null);
	const vehicleConfigValue = useMemo(
		() => ({ vehicleConfig, setVehicleConfig }),
		[vehicleConfig],
	);

	// Current Vehicle Config Context
	const [currentVehicleConfig, setCurrentVehicleConfig] = useState(null);
	const currentVehicleConfigValue = useMemo(
		() => ({ currentVehicleConfig, setCurrentVehicleConfig }),
		[currentVehicleConfig],
	);

	// paymentIntent context
	const [currentPaymentIntent, setCurrentPaymentIntent] = useState(null);
	const currentPaymentIntentValue = useMemo(
		() => ({ currentPaymentIntent, setCurrentPaymentIntent }),
		[currentPaymentIntent],
	);

	// Google maps api key context
	const [googleMapsApiKey, setGoogleMapsApiKey] = useState(null);
	const googleMapsApiKeyValue = useMemo(
		() => ({ googleMapsApiKey, setGoogleMapsApiKey }),
		[googleMapsApiKey],
	);
	const [registeredUserProps, setRegisteredUser] = useState(null);
	const registeredUserValue = useMemo(
		() => ({ registeredUserProps, setRegisteredUser }),
		[registeredUserProps],
	);

	const [selectedVariantOptions, setSelectedVariantOptions] = useState(null);
	const selectedVariantOptionsValue = useMemo(
		() => ({ selectedVariantOptions, setSelectedVariantOptions }),
		[selectedVariantOptions],
	);

	// vehicle context
	const [vehicles, setVehicles] = useState([]);
	const vehiclesValue = useMemo(() => ({ vehicles, setVehicles }), [vehicles]);

	// unique accessories context
	const [uniqueAccessories, setUniqueAccessories] = useState(null);
	const uniqueAccessoriesValue = useMemo(
		() => ({ uniqueAccessories, setUniqueAccessories }),
		[uniqueAccessories],
	);

	// accessory count context
	const [accessoryCounts, setAccessoryCounts] = useState(null);
	const accessoryCountsValue = useMemo(
		() => ({ accessoryCounts, setAccessoryCounts }),
		[accessoryCounts],
	);

	// misc ecomm data context
	const [checkoutCart, setCheckoutCart] = useState(null);
	const checkoutCartValue = useMemo(() => ({ checkoutCart, setCheckoutCart }), [checkoutCart]);
	// State for whether or not the user has bolt ons
	const [cartHasBoltOns, setCartHasBoltOns] = useState(null);
	const cartHasBoltOnsValue = useMemo(
		() => ({ cartHasBoltOns, setCartHasBoltOns }),
		[cartHasBoltOns],
	);

	// OEM vehicles context
	const [oemVehicles, setOemVehicles] = useState(null);
	const oemVehiclesValue = useMemo(() => ({ oemVehicles, setOemVehicles }), [oemVehicles]);

	// non blocking OEM pick up locations context
	const [oemPickUpLocations, setOemPickUpLocations] = useState(null);
	const oemPickUpLocationsValue = useMemo(
		() => ({ oemPickUpLocations, setOemPickUpLocations }),
		[oemPickUpLocations],
	);

	const [cartCosts, setCartCosts] = useState(null);
	const cartCostsValue = useMemo(() => ({ cartCosts, setCartCosts }), [cartCosts]);

	// for brand styling context
	const [oemProfileProps, setOemProfile] = useState(null);
	const oemProfileValue = useMemo(() => ({ oemProfileProps, setOemProfile }), [oemProfileProps]);

	// stacked context
	const [stacked, setStacked] = useState(window.innerWidth < STACKING_BREAKPOINT);
	const stackedValue = useMemo(() => ({ stacked, setStacked }), [stacked]);

	// Selected payment option context
	const [selectedPaymentOption, setSelectedPaymentOption] = useState(null);
	const selectedPaymentOptionValue = useMemo(
		() => ({ selectedPaymentOption, setSelectedPaymentOption }),
		[selectedPaymentOption],
	);

	// Selected delivery option context
	const [selectedDeliveryOption, setSelectedDeliveryOption] = useState(null);
	const selectedDeliveryOptionValue = useMemo(
		() => ({ selectedDeliveryOption, setSelectedDeliveryOption }),
		[selectedDeliveryOption],
	);

	// selected registration option context
	const [selectedRegistrationOption, setSelectedRegistrationOption] = useState(null);
	const selectedRegistrationOptionValue = useMemo(
		() => ({ selectedRegistrationOption, setSelectedRegistrationOption }),
		[selectedRegistrationOption],
	);

	// checkout fired (random)
	const [checkoutFired, setCheckoutFired] = useState(0);

	// registrationAddress context
	const [registrationAddress, setRegistrationAddress] = useState({
		addressString: '',
		addressLineOne: '',
		addressLineTwo: '',
		city: '',
		county: '',
		state: '',
		zip: '',
	});
	const registrationAddressValue = useMemo(
		() => ({ registrationAddress, setRegistrationAddress }),
		[registrationAddress],
	);

	// account details context
	const [accountDetails, setAccountDetails] = useState({
		fullName: '',
		emailAddress: '',
		phoneNumber: '',
	});
	const accountDetailsValue = useMemo(
		() => ({ accountDetails, setAccountDetails }),
		[accountDetails],
	);

	// payment intents context
	const [paymentIntents, setPaymentIntents] = useState(null);
	const paymentIntentsValue = useMemo(
		() => ({ paymentIntents, setPaymentIntents }),
		[paymentIntents],
	);

	// state data context (will only have taxes for now)
	const [stateData, setStateData] = useState(null);
	const stateDataValue = useMemo(() => ({ stateData, setStateData }), [stateData]);

	// applicable discounts context
	const [applicableDiscounts, setApplicableDiscounts] = useState([]);
	const applicableDiscountsValue = useMemo(
		() => ({
			applicableDiscounts,
			setApplicableDiscounts,
		}),
		[applicableDiscounts],
	);

	const [orderIsBeingPlaced, setOrderIsBeingPlaced] = useState(false);
	const orderIsBeingPlacedValue = useMemo(
		() => ({
			orderIsBeingPlaced,
			setOrderIsBeingPlaced,
		}),
		[orderIsBeingPlaced],
	);

	const [abandonedCartId, setAbandonedCartId] = useState(null);
	const abandonedCartIdValue = useMemo(
		() => ({
			abandonedCartId,
			setAbandonedCartId,
		}),
		[abandonedCartId],
	);

	// Delivery Estimate String
	const [deliveryTime, setDeliveryTime] = useState('Loading...');
	const deliveryTimeValue = useMemo(() => ({ deliveryTime, setDeliveryTime }), [deliveryTime]);

	// END OF CONTEXTS
	// ================ USE EFFECTS NECESSARY PRIOR TO LOADING ================ //
	useEffect(() => {
		if (process.env.REACT_APP_CURR_ENV === ENV.PRODUCTION) {
			const siteId = OEM_KEY_TO_HOTJAR_SITE_ID.CHECKOUT[process.env.REACT_APP_OEM_KEY];
			if (siteId === undefined) {
				return;
			}
			const hotjarVersion = 6;
			Hotjar.init(siteId, hotjarVersion);
		}
	}, []);

	// Conditionally load analytics (GA, TikTok Pixel, Meta Pixel) scripts if the environment variable is defined
	useEffect(() => {
		// Function to get query parameters from URL
		function getQueryParam(param) {
			const urlParams = new URLSearchParams(window.location.search);
			return urlParams.get(param);
		}

		// Function to get client_id from URL
		function getClientIdFromUrl() {
			return getQueryParam('client_id');
		}

		// Function to get session_id from URL
		function getSessionIdFromUrl() {
			return getQueryParam('session_id');
		}
		// Check if the environment variables for Google Tags are defined
		const gaTagManagerId = process.env.REACT_APP_GA_MANAGER_ID;
		const gaKey = process.env.REACT_APP_GA_KEY;
		const googleAdsKey = process.env.REACT_APP_GOOGLE_ADS_KEY;
		const WCInline = process.env.REACT_APP_WHATCONVERTS_INLINE;
		const WCExternal = process.env.REACT_APP_WHATCONVERTS_EXTERNAL;
		if (WCInline && WCExternal) {
			// Create and inject the inline script
			const inlineScript = document.createElement('script');
			inlineScript.textContent = WCInline;

			// Handle inline script error
			inlineScript.onerror = (e) => {
				console.log(`Error loading inline script: ${e}`);
			};

			document.head.appendChild(inlineScript);

			// Create and inject the external script
			const externalScript = document.createElement('script');
			externalScript.src = WCExternal;

			// Handle external script load error
			externalScript.onerror = (e) => {
				console.log(`Error loading external script: ${e}`);
			};

			document.head.appendChild(externalScript);
		}
		if (gaTagManagerId) {
			// Create a script tag for Google Tag Manager
			const scriptTag = document.createElement('script');
			scriptTag.async = true;
			scriptTag.src = `https://www.googletagmanager.com/gtm.js?id=${gaTagManagerId}`;

			// Append the script to the document head
			document.head.appendChild(scriptTag);

			// Initialize dataLayer and define gtag function globally
			window.dataLayer = window.dataLayer || [];
			window.gtag = function () {
				// eslint-disable-next-line no-undef, prefer-rest-params
				dataLayer.push(arguments);
			};

			// Handle script load
			scriptTag.onload = () => {
				window.gtag('js', new Date());
				window.gtag('config', gaTagManagerId, {
					client_id: getClientIdFromUrl(),
					session_id: getSessionIdFromUrl(),
				});
				window.gtag('event', 'begin_checkout', {
					currency: 'USD',
				});
			};

			// Handle script load error
			scriptTag.onerror = (e) => {
				console.log(`Error loading Google Tag Manager script: ${e}`);
			};
		} else if (gaKey) {
			// Create a script tag for Google Analytics
			const scriptTag = document.createElement('script');
			scriptTag.async = true;
			scriptTag.src = `https://www.googletagmanager.com/gtag/js?id=${gaKey}`;
			document.head.appendChild(scriptTag);

			// Initialize dataLayer and define gtag function globally
			window.dataLayer = window.dataLayer || [];
			window.gtag = function () {
				// eslint-disable-next-line no-undef, prefer-rest-params
				dataLayer.push(arguments);
			};

			scriptTag.onload = () => {
				// Define the global gtag function only after the script is fully loaded
				window.gtag('js', new Date());
				window.gtag('config', gaKey, {
					client_id: getClientIdFromUrl(),
					session_id: getSessionIdFromUrl(),
				});
				// Configure Google Ads if it is defined
				if (googleAdsKey) {
					window.gtag('config', googleAdsKey);
				}
				window.gtag('event', 'begin_checkout', {
					currency: 'USD',
				});
			};
		}

		// Check if the TikTok Pixel id environment variable is defined
		const tiktokPixelId = process.env.REACT_APP_TIKTOK_PIXEL_ID;
		if (tiktokPixelId) {
			// Create a script tag for TikTok Pixel
			const tiktokScriptTag = document.createElement('script');
			tiktokScriptTag.async = true;
			tiktokScriptTag.innerHTML = `
			  !function (w, d, t) {
				w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie","holdConsent","revokeConsent","grantConsent"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(
				var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var r="https://analytics.tiktok.com/i18n/pixel/events.js",o=n&&n.partner;ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=r,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script")
				;n.type="text/javascript",n.async=!0,n.src=r+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};
				  ttq.load('${tiktokPixelId}');
				  ttq.page();
			  }(window, document, 'ttq');
			`;
			document.head.appendChild(tiktokScriptTag);
		}

		// Check if the Meta Pixel id environment variable is defined
		const metaPixelId = process.env.REACT_APP_META_PIXEL_ID;
		if (metaPixelId) {
			// Create a script tag for Meta Pixel
			const metaScriptTag = document.createElement('script');
			metaScriptTag.async = true;
			metaScriptTag.innerHTML = `
				!function(f,b,e,v,n,t,s) {
					if(f.fbq)return;n=f.fbq=function(){n.callMethod?
					n.callMethod.apply(n,arguments):n.queue.push(arguments)};
					if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
					n.queue=[];t=b.createElement(e);t.async=!0;
					t.src=v;s=b.getElementsByTagName(e)[0];
					s.parentNode.insertBefore(t,s)}(window, document,'script',
					'https://connect.facebook.net/en_US/fbevents.js');
				fbq('init', '${metaPixelId}');
				fbq('track', 'PageView');`;

			// Append the script tag to the document head
			document.head.appendChild(metaScriptTag);

			// Create a noscript tag for Meta Pixel
			const metaScriptNoTag = document.createElement('noscript');
			metaScriptNoTag.innerHTML = `<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=${metaPixelId}&ev=PageView&noscript=1"/>`;

			// Append the noscript tag to the document body
			document.body.appendChild(metaScriptNoTag);
		}
	}, []);

	// Listen for window resize events
	useEffect(() => {
		const debouncedHandleResize = debounceSimple(function handleResize() {
			setStacked(window.innerWidth < STACKING_BREAKPOINT);
		}, 100);
		window.addEventListener('resize', debouncedHandleResize);
		return () => {
			window.removeEventListener('resize', debouncedHandleResize);
		};
	});

	async function getOem() {
		const { REACT_APP_OEM_KEY } = process.env;
		if (!(REACT_APP_OEM_KEY in oemMap)) {
			throw new Error('Error: no OEM with provided name!');
		}
		const oemId = getOemId(REACT_APP_OEM_KEY, process.env.REACT_APP_BRAND_KEY);
		const { oem } = await getUnauthenticatedOemData(oemId);
		setOemProfile(oem);
	}
	// run this async
	async function getAndSetOemVehicles(vehiclesArr) {
		// get all unique productIds from vehicles
		const productIds = new Set();
		vehiclesArr.forEach((vehicle) => {
			productIds.add(vehicle.productId);
		});
		// get all vehicles with those productIds using the getOemVehicles endpoint
		// get the oemId from the environment variables instead of from the checkoutCart
		const { REACT_APP_OEM_KEY, REACT_APP_BRAND_KEY } = process.env;
		const oemId = getOemId(REACT_APP_OEM_KEY, REACT_APP_BRAND_KEY);
		const { vehicles: fetchedOemVehicles } = await getOemVehicles(oemId, [...productIds]);
		setOemVehicles(fetchedOemVehicles);
	}
	// run this async
	async function getAndSetOemPickUpLocations() {
		const { REACT_APP_OEM_KEY, REACT_APP_BRAND_KEY } = process.env;
		const oemId = getOemId(REACT_APP_OEM_KEY, REACT_APP_BRAND_KEY);
		const { pickUpLocations } = await getOemPickUpLocations(oemId);
		setOemPickUpLocations(pickUpLocations);
	}
	// create async function to fetch state taxes and fees using the getStateTaxeAndTarData endpoint
	async function getAndSetStateData() {
		const stateDataObj = await getStateTaxAndTARData();
		setStateData(stateDataObj);
	}
	async function getGoogleMapsApiKey() {
		const { key } = await fetchGoogleMapsApiKey();
		setGoogleMapsApiKey(key);
	}
	async function fetchVehicleConfig(productId) {
		const { vehicleConfigData, defaultVehicleConfigData } = await getVehicleConfig(productId);
		const searchParams = new URLSearchParams(window.location.search);
		const encodedConfig = searchParams.get('config');
		if (encodedConfig) {
			const decodedConfig = JSON.parse(decodeURIComponent(encodedConfig));
			await setCurrentVehicleConfig(decodedConfig);
		} else {
			await setCurrentVehicleConfig(defaultVehicleConfigData);
		}
		await setVehicleConfig(vehicleConfigData);

		// FETCH OEM VEHICLE BY PRODUCT_ID
		const { REACT_APP_OEM_KEY, REACT_APP_BRAND_KEY } = process.env;
		const oemId = getOemId(REACT_APP_OEM_KEY, REACT_APP_BRAND_KEY);
		const { vehicles: fetchedOemVehicles } = await getOemVehicles(oemId, [productId]);
		const vehiclesArr = Object.values(fetchedOemVehicles);
		await getAndSetOemVehicles(vehiclesArr);
		await setVehicles(vehiclesArr);

		// RANDOM STUFF (for now)
		await getAndSetStateData();
		await setActiveModule(MODULES.CONFIGURATOR);
	}
	async function fetchCheckoutData(existingCartId) {
		const searchParams = new URLSearchParams(window.location.search);
		setIsLoading(true);
		await getAndSetOemPickUpLocations();
		await getAndSetStateData();
		await getGoogleMapsApiKey();
		const { REACT_APP_OEM_KEY } = process.env;
		createLog('getAndSetCheckoutCart', `REACT_APP_OEM_KEY: ${REACT_APP_OEM_KEY}`, 'INFO', {});
		if (!(REACT_APP_OEM_KEY in oemMap)) {
			throw new Error('Error: no OEM with provided name!');
		}
		let cartId;
		if (searchParams.get('cartId')) {
			cartId = searchParams.get('cartId');
		}
		// SWITCHING TO CHECKOUT FROM CONFIGURATOR
		if (existingCartId) {
			cartId = existingCartId;
		}
		// get cartId from URL params
		const checkoutCartData = await getCheckoutCart(cartId);
		const {
			id: checkoutCartId,
			nativeDefaultCheckoutUrl,
			registrationState: preSelectedRegistrationState,
			vehicles: vehiclesArr,
			uniqueAccessoriesMap,
			initAccessoryCounts,
			hasBoltOns,
			discounts,
		} = checkoutCartData;
		if (
			checkoutCartData.orderCreationType === ORDER_CREATION_TYPES.ABANDONEDCART ||
			checkoutCartData.orderCreationType === ORDER_CREATION_TYPES.ABANDONED_ANTE_PREORDER
		) {
			setAbandonedCartId(checkoutCartId);
		}
		// if the 'preview' query param is not true, then call checkoutCartWasOpened to log that the checkout cart was opened
		if (searchParams.get('preview') !== 'true') {
			checkoutCartWasOpened(checkoutCartId);
		}
		if (preSelectedRegistrationState) {
			setRegistrationAddress({
				addressString: '',
				addressLineOne: '',
				addressLineTwo: '',
				city: '',
				county: '',
				state: preSelectedRegistrationState,
				zip: '',
			});
		} else {
			setRegistrationAddress({
				addressString: '',
				addressLineOne: '',
				addressLineTwo: '',
				city: '',
				county: '',
				state: '',
				zip: '',
			});
		}
		// Redirect to default checkout if there are no vehicles
		if (vehiclesArr.length === 0) {
			window.location.href = nativeDefaultCheckoutUrl;
			// Return so that the page doesn't load with no vehicles
			return;
		}
		await getAndSetOemVehicles(vehiclesArr);
		await setCartHasBoltOns(hasBoltOns);
		await setCheckoutCart(checkoutCartData);
		await setVehicles(vehiclesArr);
		await setSelectedVariantOptions(getVariantOptionsFromVehicles(vehiclesArr));
		await setUniqueAccessories(uniqueAccessoriesMap);
		await setAccessoryCounts(initAccessoryCounts);
		await updateDynamicCSS(vehiclesArr, uniqueAccessoriesMap);
		// if there are discounts, update the applicableDiscounts context
		if (discounts?.length) {
			const formattedDiscounts = discounts.map((discount) => {
				return {
					name: discount.name,
					type: discount.type,
					amount: discount.amount,
					applicableTo: discount.applicableTo,
					reid: discount.reid || null,
					campaignId: discount.campaignId || null,
					category: discount.category,
				};
			});
			// only allow a single discount at the moment => return the discount obj with the largest amount
			const singleDiscount = formattedDiscounts.sort((a, b) => b.amount - a.amount)[0];
			await setApplicableDiscounts([singleDiscount]);
		}
		await setCheckoutFired(Math.random());
	}
	function updateDynamicCSS(vehiclesArr, uniqueAccessoriesMap) {
		// set dynamic css variables for mobile view
		const root = document.documentElement;
		if (vehiclesArr.length === 0) {
			root.style.setProperty('--purchase-preview-percent-height', '45vh');
			root.style.setProperty(
				'--carousel-percent-height',
				'calc((var(--purchase-preview-percent-height) - 55px) * 0.60)',
			);
		}
		if (vehiclesArr.length !== 0 && uniqueAccessoriesMap.size === 0) {
			// let the carousel take up a bigger percentage in mobile view when no accessories & only vehicles
			root.style.setProperty('--purchase-preview-percent-height', '45vh');
			root.style.setProperty(
				'--carousel-percent-height',
				'calc((var(--purchase-preview-percent-height) - 55px) * 0.70)',
			);
		}
	}

	// parse URL params, and set dynamic CSS
	useEffect(() => {
		const searchParams = new URLSearchParams(window.location.search);
		// manually handle sign ins to avoid situation where we create an auth account and user db obj not yet create
		// this will exclusively be used for signouts
		onAuthStateChanged(auth, async (buyer) => {
			if (!buyer) {
				setRegisteredUser(null);
			}
		});

		if (oemProfileProps === null) {
			getOem();
		}

		if (
			(window.location.pathname === '/checkout' ||
				window.location.pathname === '/PurchaseSuccessPage') &&
			uniqueAccessories === null
		) {
			const productId = searchParams.get('productId');
			if (productId) {
				fetchVehicleConfig(productId);
			} else {
				fetchCheckoutData();
			}
		}
	}, []);

	useEffect(() => {
		function handleBeforeUnload() {
			track_checkout_buyer_abandoned_cart(
				vehicles,
				uniqueAccessories,
				oemProfileProps,
				checkoutCart,
				abandonedCartId,
				isPurchasePreorder(checkoutCart),
			);
		}

		window.addEventListener('beforeunload', handleBeforeUnload);

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, [vehicles, uniqueAccessories, oemProfileProps, checkoutCart, abandonedCartId]);

	// create paymentIntent when necessary information is available
	useEffect(() => {
		// Write an async function that creates three different payment intents using a promise array and the createCheckoutPaymentIntent function
		async function initPaymentIntent() {
			// once we have the oem, create a paymentIntent
			if (isPurchasePreorder(checkoutCart)) {
				const paymentIntentsRes = await Promise.all([
					// PREORDER RESERVATION
					createCheckoutPaymentIntent(
						PAYMENT_DESCRIPTION_TYPES.PREORDER_PAYMENT.description, // paymentDescription
						9000, // amountToPay
						PAYMENT_TYPES.PREORDER_RESERVATION, // paymentType
						checkoutCart?.id, // checkoutCartId
						checkoutCart.oemId,
						false, // processingFeeInc
						0, // processingFeeIncAmount
						[STRIPE_PAYMENT_METHOD_TYPES.CARD], // paymentMethodTypes
					),
					// VIP PREORDER RESERVATION
					createCheckoutPaymentIntent(
						PAYMENT_DESCRIPTION_TYPES.PREORDER_PAYMENT.description, // paymentDescription
						9000, // amountToPay
						PAYMENT_TYPES.PREORDER_VIP_RESERVATION, // paymentType
						checkoutCart?.id, // checkoutCartId
						checkoutCart.oemId,
						false, // processingFeeInc
						0, // processingFeeIncAmount
						[STRIPE_PAYMENT_METHOD_TYPES.CARD], // paymentMethodTypes
					),
				]);
				const paymentIntentsFutureValue = {
					[PAYMENT_CHOICES.PREORDER_RESERVATION]: paymentIntentsRes[0],
					[PAYMENT_CHOICES.PREORDER_VIP_RESERVATION]: paymentIntentsRes[1],
				};
				setPaymentIntents(paymentIntentsFutureValue);
				setCurrentPaymentIntent(paymentIntentsFutureValue[selectedPaymentOption]);
			} else {
				const paymentIntentsRes = await Promise.all([
					// PAY IN FULL
					createCheckoutPaymentIntent(
						PAYMENT_DESCRIPTION_TYPES.REMAINING_BALANCE_PAYMENT.description, // paymentDescription
						9000, // amountToPay
						PAYMENT_TYPES.CHECKOUT_FULL_PAYMENT, // paymentType
						checkoutCart?.id, // checkoutCartId
						checkoutCart.oemId,
						false, // processingFeeInc
						0, // processingFeeIncAmount
						[STRIPE_PAYMENT_METHOD_TYPES.CARD], // paymentMethodTypes
					),
					// FINANCING
					createCheckoutPaymentIntent(
						PAYMENT_DESCRIPTION_TYPES.RESERVATION_PAYMENT.description, // paymentDescription
						9000, // amountToPay
						PAYMENT_TYPES.LOAN_DOWNPAYMENT, // paymentType
						checkoutCart?.id, // checkoutCartId
						checkoutCart.oemId,
						false, // processingFeeInc
						0, // processingFeeIncAmount
						[STRIPE_PAYMENT_METHOD_TYPES.CARD], // paymentMethodTypes
					),
					// RESERVATION
					createCheckoutPaymentIntent(
						PAYMENT_DESCRIPTION_TYPES.RESERVATION_PAYMENT.description, // paymentDescription
						9000, // amountToPay
						PAYMENT_TYPES.STANDARD_RESERVATION, // paymentType
						checkoutCart?.id, // checkoutCartId
						checkoutCart.oemId,
						false, // processingFeeInc
						0, // processingFeeIncAmount
						[STRIPE_PAYMENT_METHOD_TYPES.CARD], // paymentMethodTypes
					),
					// KLARNA PAY IN FULL
					createCheckoutPaymentIntent(
						PAYMENT_DESCRIPTION_TYPES.REMAINING_BALANCE_PAYMENT.description, // paymentDescription
						9000, // amountToPay
						PAYMENT_TYPES.KLARNA_CHECKOUT_FULL_PAYMENT, // paymentType
						checkoutCart?.id, // checkoutCartId
						checkoutCart.oemId,
						false, // processingFeeInc
						0, // processingFeeIncAmount
						[STRIPE_PAYMENT_METHOD_TYPES.KLARNA], // paymentMethodTypes
					),
				]);
				const paymentIntentsFutureValue = {
					[PAYMENT_CHOICES.CHECKOUT_FULL_PAYMENT]: paymentIntentsRes[0],
					[PAYMENT_CHOICES.LOAN_DOWNPAYMENT]: paymentIntentsRes[1],
					[PAYMENT_CHOICES.STANDARD_RESERVATION]: paymentIntentsRes[2],
					[PAYMENT_CHOICES.KLARNA_CHECKOUT_FULL_PAYMENT]: paymentIntentsRes[3],
				};
				setPaymentIntents(paymentIntentsFutureValue);
				setCurrentPaymentIntent(paymentIntentsFutureValue[selectedPaymentOption]);
			}
			await setIsLoading(false);
			await setActiveModule(MODULES.PAYMENT);
		}

		// only run if a.) oem has been set and b.) checkoutCart has been unpacked, and c.) paymentIntent hasn't been created yet d.) location is /checkout and e.) payment option has been selected
		const { pathname } = window.location;
		if (
			vehicles.length > 0 &&
			uniqueAccessories !== null &&
			accessoryCounts !== null &&
			currentPaymentIntent === null &&
			pathname === '/checkout' &&
			selectedPaymentOption !== null
		) {
			// if we are not re-using a checkout cart that is associated with an existing order, create a new payment intent
			// if (!miscEcommData.placedOrderId) {
			initPaymentIntent();
			// }
		} else if (
			vehicles.length > 0 &&
			uniqueAccessories !== null &&
			accessoryCounts !== null &&
			currentPaymentIntent != null &&
			pathname === '/checkout' &&
			selectedPaymentOption !== null
		) {
			setIsLoading(false);
			setActiveModule(MODULES.PAYMENT);
		}
	}, [
		oemProfileProps,
		vehicles,
		uniqueAccessories,
		accessoryCounts,
		checkoutCart,
		selectedPaymentOption,
		checkoutFired,
	]);

	// useEffect to update the currentPaymentIntent whenever the selectedPaymentOption changes
	useEffect(() => {
		if (paymentIntents !== null && selectedPaymentOption) {
			setCurrentPaymentIntent(paymentIntents[selectedPaymentOption]);
		}
	}, [selectedPaymentOption]);

	// useEffect that runs when OEM and checkoutCart are available to set the default selectedPaymentOption and selectedDeliveryOption and selectedRegistrationOption
	useEffect(() => {
		if (
			oemProfileProps &&
			oemVehicles &&
			checkoutCart &&
			selectedPaymentOption === null &&
			selectedDeliveryOption === null &&
			selectedRegistrationOption === null
		) {
			const checkoutPaymentOverride =
				checkoutCart?.customLinkConfig?.checkoutPaymentOverride ?? null;
			const paymentConfig = getPaymentConfigOptions(
				oemProfileProps,
				Object.values(oemVehicles),
				checkoutPaymentOverride,
				isPurchasePreorder(checkoutCart),
			);
			if (isPurchasePreorder(checkoutCart)) {
				const {
					PREORDER_RESERVATION: oemPreorderReservation,
					PREORDER_VIP_RESERVATION: oemPreorderVipReservation,
				} = paymentConfig;
				// configure ability to pre-select
				let prefilledPaymentOption = null;
				// let prefilledDeliveryOption = null;
				if (checkoutCart.customLinkConfig?.prefilledInfo) {
					const { selectedPaymentOption: tempSelectedPaymentOption } =
						checkoutCart.customLinkConfig.prefilledInfo;
					if (tempSelectedPaymentOption) {
						prefilledPaymentOption = tempSelectedPaymentOption;
					}
				}
				if (prefilledPaymentOption) {
					if (
						PAYMENT_CHOICES.PREORDER_RESERVATION === prefilledPaymentOption &&
						oemPreorderReservation
					) {
						setSelectedPaymentOption(PAYMENT_CHOICES.PREORDER_RESERVATION);
					} else if (
						PAYMENT_CHOICES.PREORDER_VIP_RESERVATION === prefilledPaymentOption &&
						oemPreorderVipReservation
					) {
						setSelectedPaymentOption(PAYMENT_CHOICES.PREORDER_VIP_RESERVATION);
					} else {
						setSelectedPaymentOption(PAYMENT_CHOICES.PREORDER_RESERVATION);
					}
				} else if (oemPreorderReservation) {
					setSelectedPaymentOption(PAYMENT_CHOICES.PREORDER_RESERVATION);
				} else if (oemPreorderVipReservation) {
					setSelectedPaymentOption(PAYMENT_CHOICES.PREORDER_VIP_RESERVATION);
				}
			} else {
				const {
					CHECKOUT_FULL_PAYMENT: oemCheckoutFullPayment,
					STANDARD_RESERVATION: oemStandardReservation,
					LOAN_DOWNPAYMENT: oemLoanDownpayment,
					KLARNA_CHECKOUT_FULL_PAYMENT: oemKlarnaCheckoutFullPayment,
				} = paymentConfig;
				// const prefilledRegistrationOption = null;
				let prefilledPaymentOption = null;
				// let prefilledDeliveryOption = null;
				if (checkoutCart.customLinkConfig?.prefilledInfo) {
					const { selectedPaymentOption: tempSelectedPaymentOption } =
						checkoutCart.customLinkConfig.prefilledInfo;
					if (tempSelectedPaymentOption) {
						prefilledPaymentOption = tempSelectedPaymentOption;
					}
				}
				// For payment, double check that this OEM supports this payment option before we set this
				if (prefilledPaymentOption) {
					if (
						PAYMENT_CHOICES.CHECKOUT_FULL_PAYMENT === prefilledPaymentOption &&
						oemCheckoutFullPayment
					) {
						setSelectedPaymentOption(PAYMENT_CHOICES.CHECKOUT_FULL_PAYMENT);
					} else if (
						PAYMENT_CHOICES.STANDARD_RESERVATION === prefilledPaymentOption &&
						oemStandardReservation
					) {
						setSelectedPaymentOption(PAYMENT_CHOICES.STANDARD_RESERVATION);
					} else if (
						PAYMENT_CHOICES.LOAN_DOWNPAYMENT === prefilledPaymentOption &&
						oemLoanDownpayment
					) {
						setSelectedPaymentOption(PAYMENT_CHOICES.LOAN_DOWNPAYMENT);
					} else {
						setSelectedPaymentOption(PAYMENT_CHOICES.LOAN_DOWNPAYMENT);
						// throw new Error('No payment options available');
					}
				} else if (oemLoanDownpayment) {
					// If no prefilledPaymentOption, but the OEM supports financing, default to financing
					setSelectedPaymentOption(PAYMENT_CHOICES.LOAN_DOWNPAYMENT);
				} else if (oemCheckoutFullPayment) {
					// If no prefilledPaymentOption, and the OEM doesn't support financing, default to full payment
					setSelectedPaymentOption(PAYMENT_CHOICES.CHECKOUT_FULL_PAYMENT);
				} else if (oemStandardReservation) {
					// If no prefilledPaymentOption, and the OEM doesn't support financing, or checkout full payment, default to cash reservation
					setSelectedPaymentOption(PAYMENT_CHOICES.STANDARD_RESERVATION);
				} else if (oemKlarnaCheckoutFullPayment) {
					// If no prefilledPaymentOption, and the OEM doesn't support financing, or checkout full payment, or cash reservation, default to Klarna
					setSelectedPaymentOption(PAYMENT_CHOICES.KLARNA_CHECKOUT_FULL_PAYMENT);
				} else {
					// If no prefilledPaymentOption, and the OEM doesn't support financing, or checkout full payment, or cash reservation, throw an error
					throw new Error('No payment options available');
				}
			}

			setSelectedDeliveryOption(DELIVERY_CHOICES.SHIP);
		}
	}, [oemProfileProps, checkoutCart, oemVehicles]);

	/**
	 * Dark Mode
	 */
	const { themeStatus, darkModeStatus } = useDarkMode();
	const theme = {
		theme: themeStatus,
		primary: COLORS.PRIMARY.code,
		secondary: COLORS.SECONDARY.code,
		success: COLORS.SUCCESS.code,
		info: COLORS.INFO.code,
		warning: COLORS.WARNING.code,
		danger: COLORS.DANGER.code,
		dark: COLORS.DARK.code,
		light: COLORS.LIGHT.code,
	};
	useEffect(() => {
		if (darkModeStatus) {
			document.documentElement.setAttribute('theme', 'dark');
		}
		return () => {
			document.documentElement.removeAttribute('theme');
		};
	}, [darkModeStatus]);

	/**
	 * Full Screen
	 */
	const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
	const ref = useRef(null);
	useFullscreen(ref, fullScreenStatus, {
		onClose: () => setFullScreenStatus(false),
	});

	/**
	 * Modern Design
	 */
	useLayoutEffect(() => {
		if (process.env.REACT_APP_MODERN_DESGIN === 'true') {
			document.body.classList.add('modern-design');
		} else {
			document.body.classList.remove('modern-design');
		}
	});

	//	Add paths to the array that you don't want to be "Aside".
	const withOutAsidePages = [
		demoPages.login.path,
		demoPages.signUp.path,
		layoutMenu.blank.path,
		layoutMenu.checkout.path,
		layoutMenu.configurator.path,
		dashboardMenu.PurchaseSuccessPage.path,
	];

	return (
		<ContextWrapper
			registeredUserValue={registeredUserValue}
			oemProfileValue={oemProfileValue}
			currentPaymentIntentValue={currentPaymentIntentValue}
			vehiclesValue={vehiclesValue}
			uniqueAccessoriesValue={uniqueAccessoriesValue}
			accessoryCountsValue={accessoryCountsValue}
			checkoutCartValue={checkoutCartValue}
			cartHasBoltOns={cartHasBoltOnsValue}
			oemVehiclesValue={oemVehiclesValue}
			stackedValue={stackedValue}
			oemPickUpLocationsValue={oemPickUpLocationsValue}
			cartCostsValue={cartCostsValue}
			selectedPaymentOptionValue={selectedPaymentOptionValue}
			selectedDeliveryOptionValue={selectedDeliveryOptionValue}
			selectedRegistrationOptionValue={selectedRegistrationOptionValue}
			registrationAddressValue={registrationAddressValue}
			stateDataValue={stateDataValue}
			paymentIntentsValue={paymentIntentsValue}
			applicableDiscountsValue={applicableDiscountsValue}
			orderIsBeingPlacedValue={orderIsBeingPlacedValue}
			abandonedCartIdValue={abandonedCartIdValue}
			googleMapsApiKeyValue={googleMapsApiKeyValue}
			selectedVariantOptionsValue={selectedVariantOptionsValue}
			accountDetailsValue={accountDetailsValue}
			vehicleConfigValue={vehicleConfigValue}
			currentVehicleConfigValue={currentVehicleConfigValue}
			fetchCheckoutValue={fetchCheckoutValue}
			activeModuleValue={activeModuleValue}
			deliveryTimeValue={deliveryTimeValue}>
			<ThemeProvider theme={theme}>
				<TourProvider
					steps={steps}
					styles={styles}
					showNavigation={false}
					showBadge={false}>
					<AppRouter
						fullScreenStatus={fullScreenStatus}
						ref={ref}
						oemProfileProps={oemProfileProps}
						vehicles={vehicles}
						oemVehicles={oemVehicles}
						uniqueAccessories={uniqueAccessories}
						accessoryCounts={accessoryCounts}
						checkoutCart={checkoutCart}
						stateData={stateData}
						selectedDeliveryOption={selectedDeliveryOption}
						selectedPaymentOption={selectedPaymentOption}
						stacked={stacked}
						withOutAsidePages={withOutAsidePages}
						vehicleConfig={vehicleConfig}
						currentVehicleConfig={currentVehicleConfig}
					/>
					<Portal id='portal-notification'>
						<ReactNotifications />
					</Portal>
				</TourProvider>
				{/* </ToastProvider> */}
			</ThemeProvider>
		</ContextWrapper>
	);
};

export default App;
