/* ENVIRONMENT CONSTS */
import { PAYMENT_TYPES } from '@ekhodealer/ekho-common';

export const COLORS = {
	PRIMARY: {
		name: 'primary',
		code: process.env.REACT_APP_PRIMARY_COLOR,
	},
	SECONDARY: {
		name: 'secondary',
		code: process.env.REACT_APP_SECONDARY_COLOR,
	},
	SUCCESS: {
		name: 'success',
		code: process.env.REACT_APP_SUCCESS_COLOR,
	},
	INFO: {
		name: 'info',
		code: process.env.REACT_APP_INFO_COLOR,
	},
	WARNING: {
		name: 'warning',
		code: process.env.REACT_APP_WARNING_COLOR,
	},
	DANGER: {
		name: 'danger',
		code: process.env.REACT_APP_DANGER_COLOR,
	},
	DARK: {
		name: 'dark',
		code: process.env.REACT_APP_DARK_COLOR,
	},
	LIGHT: {
		name: 'light',
		code: process.env.REACT_APP_LIGHT_COLOR,
	},
	// Color Hex codes
	BACKGROUND_GRAY: {
		name: 'backgroundGray',
		code: '#f8f9fa',
	},
	BORDER_GRAY: {
		name: 'borderGray',
		code: 'var(--bs-card-border-color)',
	},
};

export const ENV = {
	DEVELOPMENT: 'development',
	STAGING: 'staging',
	PRODUCTION: 'production',
};

/* END ENVIRONMENT CONSTS */

/* AUTH CONSTS */

export const ACCOUNT_TYPE = {
	BUYER: 'buyer',
	ADMIN: 'Oemadmin',
};

export const VALID_LOGIN_TYPE = {
	ADMIN: 'admin',
	EMAIL_VERIFICATION: 'emailVerification',
	BUYER: 'buyer',
};

/* AUTH CONSTS */

/* VEHICLE CONSTS */

export const VEHICLE_STAGE = {
	SIGN_OFF: 'signOff',
	PENDING_PAYMENT_GUARANTEE: 'pendingPaymentGuarantee',
	PENDING_VERIFICATION: 'pendingVerification',
	PRODUCTION: 'production',
	COMPLETE: 'complete',
};

export const DELIVERY_DATE_FORMATS = {
	EST_DELIVERY_DATE: 'EST_DELIVERY_DATE',
	DELIVERY_WITHIN: 'DELIVERY_WITHIN',
};

export const DEFAULT_CHECKOUT_CONFIG = {
	deliveryDateFormat: DELIVERY_DATE_FORMATS.EST_DELIVERY_DATE,
};

export const VEHICLE_STAGE_TO_STRING_MAP = {
	[VEHICLE_STAGE.SIGN_OFF]: 'Sign off',
	[VEHICLE_STAGE.PENDING_PAYMENT_GUARANTEE]: 'Pending payment guarantee',
	[VEHICLE_STAGE.PENDING_VERIFICATION]: 'Pending identity verification',
	[VEHICLE_STAGE.PRODUCTION]: 'Production',
	[VEHICLE_STAGE.COMPLETE]: 'Complete',
};

/* END VEHICLE CONSTS */

/* PAYMENT CONSTS */

// Payment type used with stripe payments
export const PAYMENT_TYPE = {
	FULL_PAYMENT: 'fullPayment',
	DOWN_PAYMENT: 'downPayment',
};

export const TEST_DEPOSIT_AMOUNT = 0.01;

export const KLARNA_CHECKOUT_MIN = 4000;
export const KLARNA_CHECKOUT_MAX = 10000;

// export const AVERAGE_COMBINED_SALES_TAX_PERCENT = 0.092;

/* END PAYMENT CONSTS */

/* SECTION CONSTS */

export const sectionKeys = {
	financing: 'financing',
	deliveryDetails: 'deliveryDetails',
	registrationAndTitling: 'registrationAndTitling',
	insurance: 'insurance',
	documentsAndSignatures: 'documentsAndSignatures',
	payment: 'payment',
};

export const ACTIVE_SECTION_KEY = 'activeSection';

export const COMPLETED_PAGE_SUFFIX = 'CompletedPage';
// eslint-disable-next-line import/prefer-default-export
export const AllPageNames = {
	//
	// FINANCING
	//
	financingKickOffPage: 'financingKickOffPage',
	financingChoicePage: 'financingChoicePage',
	financingInfoCollect: 'financingInfoCollect',
	confirmNativeFinancingDetailsPage: 'confirmNativeFinancingDetailsPage',
	checkDiffLenderTiming: 'checkDiffLenderTiming',
	checkSubmitCreditApp: 'checkSubmitCreditApp',
	otherLenderInfoCollect: 'otherLenderInfoCollect',
	confirmOtherLenderDetailsPage: 'confirmOtherLenderDetailsPage',
	waitForOtherLenderPayment: 'waitForOtherLenderPayment',
	creditAppRejected: 'creditAppRejected',
	creditAppMoreInfoNeeded: 'creditAppMoreInfoNeeded',
	awaitingFinanceDecision: 'awaitingFinanceDecision',
	nativeFinancingChoiceCollect: 'nativeFinancingChoiceCollect',
	nativeFinancingOfferDecisionCollect: 'nativeFinancingOfferDecisionCollect',
	nativeFinancingCompletedPage: 'nativeFinancingCompletedPage',
	thirdPartyFinancingCompletedPage: 'thirdPartyFinancingCompletedPage',
	cashFinancingCompletedPage: 'cashFinancingCompletedPage',
	nativeFinancingSignatures: 'nativeFinancingSignatures',
	// nativeDownPaymentMethodChoice: 'nativeDownPaymentMethodChoice',
	// nativeDownPaymentBankInfoWrapper: 'nativeDownPaymentBankInfoWrapper',
	// nativeDownPaymentCardInfoCollect: 'nativeDownPaymentCardInfoCollect',
	financingDownPaymentInfoWrapper: 'financingDownPaymentInfoWrapper',
	waitingForDownPaymentACH: 'waitingForDownPaymentACH',
	downPaymentCardCompletedPage: 'downPaymentCardCompletedPage',
	// waitingForNativeDownPayment: 'waitingForNativeDownPayment',
	waitingForNativeLenderWire: 'waitingForNativeLenderWire',
	//
	// Insurance
	//
	insuranceKickOffPage: 'insuranceKickOffPage',
	insuranceChoicePage: 'insuranceChoicePage',
	insuranceRequirementsPage: 'insuranceRequirementsPage',
	noInsuranceYetPage: 'noInsuranceYetPage',
	verifyInsurancePage: 'verifyInsurancePage',
	insuranceNotValidPage: 'insuranceNotValidPage',
	insuranceCompletedPage: 'insuranceCompletedPage',

	//
	// REGISTRATION AND TITLING
	//
	registrationAndTitlingKickOffPage: 'registrationAndTitlingKickOffPage',
	registrationAndTitlingChoicePage: 'registrationAndTitlingChoicePage',
	regAndTitlingInfoCollect: 'regAndTitlingInfoCollect',
	confirmRegistrationDetailsPage: 'confirmRegistrationDetailsPage',
	regAndTitlingProcessPage: 'regAndTitlingProcessPage',
	regAndTitlingWaitForBuyerRegForms: 'regAndTitlingWaitForBuyerRegForms',
	regAndTitlingNotaryPage: 'regAndTitlingNotaryPage',
	// regAndTitlingFileUploadDynamic: 'regAndTitlingFileUploadDynamic',
	// regAndTitlingUploadInsurance: 'regAndTitlingUploadInsurance',
	regAndTitlingUploadID: 'regAndTitlingUploadID',
	downloadBuyerRegForms: 'downloadBuyerRegForms',
	selfRegistrationCompletedPage: 'selfRegistrationCompletedPage',
	ekhoDealerRegistrationCompletedPage: 'ekhoDealerRegistrationCompletedPage',
	//
	// DOCUMENTS AND SIGNATURES
	//
	documentsAndSignaturesKickOffPage: 'documentsAndSignaturesKickOffPage',
	documentsSignatures: 'documentsSignatures',
	documentsCompletedPage: 'documentsCompletedPage',
	//
	// PAYMENT KICK OFF PAGE
	//
	paymentKickOffPage: 'paymentKickOffPage',
	vehicleFullPaymentInfoWrapper: 'vehicleFullPaymentInfoWrapper',
	// bankInfoWrapper: 'bankInfoWrapper',
	// creditCardInfoWrapper: 'creditCardInfoWrapper',
	waitingForFullPaymentACH: 'waitingForFullPaymentACH',
	// bankPaymentCompletedPage: 'bankPaymentCompletedPage',
	fullCashPaymentCompletedPage: 'fullCashPaymentCompletedPage',
	alreadyFinancedPaymentCompletedPage: 'alreadyFinancedPaymentCompletedPage',
	//
	// DELIVERY DETAILS
	//
	deliveryDetailsKickOffPage: 'deliveryDetailsKickOffPage',
	deliveryDetailsChoicePage: 'deliveryDetailsChoicePage',
	deliveryDetailsCollect: 'deliveryDetailsCollect',
	pickUpInfoCollect: 'pickUpInfoCollect',
	confirmDeliveryDetailsPage: 'confirmDeliveryDetailsPage',
	confirmPickUpDetailsPage: 'confirmPickUpDetailsPage',
	deliveryCompletedPage: 'deliveryCompletedPage',
	pickUpCompletedPage: 'pickUpCompletedPage',
};

export const sectionDataMap = {
	financing: {
		numSteps: 5,
		highLevelInfo: {
			title: '01 Financing',
			timeEst: '10 minutes',
			prereqs: null,
			progress: 'Not started',
		},
	},
	deliveryDetails: {
		numSteps: 3,
		highLevelInfo: {
			title: '02 Delivery Details',
			timeEst: '2 minutes',
			prereqs: ['Financing'],
			progress: 'Not started',
		},
	},
	registrationAndTitling: {
		numSteps: 7,
		highLevelInfo: {
			title: '03 Registration and Titling',
			timeEst: '10 minutes',
			prereqs: ['Delivery Details'],
			progress: 'Not started',
		},
	},
	insurance: {
		numSteps: 5,
		highLevelInfo: {
			title: '04 Insurance',
			timeEst: '3 minutes',
			prereqs: ['Registration and Titling'],
			progress: 'Not started',
		},
	},
	documentsAndSignatures: {
		numSteps: 3,
		highLevelInfo: {
			title: '05 Documents and Signatures',
			timeEst: '2 minutes',
			prereqs: ['Insurance'],
			progress: 'Not started',
		},
	},
	payment: {
		numSteps: 3,
		highLevelInfo: {
			title: '06 Payment',
			timeEst: '5 minutes',
			prereqs: ['Documents and Signatures'],
			progress: 'Not started',
		},
	},
};

const regDetails = {
	registrantType: {
		title: 'Registrant type',
		rank: 1,
	},
	registrationState: {
		title: 'Registration State',
		rank: 2,
	},
	plateCategory: {
		title: 'Plate category',
		rank: 3,
	},
	useType: {
		title: 'Vehicle use type',
		rank: 4,
	},
	paymentType: {
		title: 'Payment Method',
		rank: 5,
	},
	firstName: {
		title: 'Legal first name',
		rank: 6,
	},
	middleName: {
		title: 'Legal middle name',
		rank: 7,
	},
	lastName: {
		title: 'Legal last name',
		rank: 8,
	},
	sex: {
		title: 'Sex',
		rank: 10,
	},
	dob: {
		title: 'Date of birth',
		rank: 9,
	},
	dlNumber: {
		title: "Driver's license number",
		rank: 10,
	},
	dlState: {
		title: "Driver's license state",
		rank: 11,
	},
	dlExpDate: {
		title: "Driver's expiration date",
		rank: 12,
	},
	phoneNumber: {
		title: 'Phone number',
		rank: 13,
	},
	email: {
		title: 'Email address',
		rank: 14,
	},
	mailAddressSameAsReg: {
		title: 'Mailing address same as registration address',
		rank: 15,
	},
	isOwnerMilitary: {
		title: 'Owner is military',
		rank: 16,
	},
	registrationAddress: {
		addressLineOne: {
			title: 'Registration address line 1',
			rank: 17,
		},
		addressLineTwo: {
			title: 'Registration address line 2',
			rank: 18,
		},
		city: {
			title: 'Registration address city',
			rank: 19,
		},
		county: {
			title: 'Registration address county',
			rank: 20,
		},
		state: {
			title: 'Registration address state',
			rank: 21,
		},
		zip: {
			title: 'Registration address zip',
			rank: 22,
		},
	},
};

export const fieldMaps = {
	nativeFinancingChoiceFieldMap: {
		name: {
			title: 'Loan offer name',
			rank: 1,
		},
		loanTerm: {
			title: 'Loan term (in months)',
			rank: 2,
		},
		apr: {
			title: 'APR of your loan (in percent)',
			rank: 3,
		},
		// Removing these for now since we don't want buyers to be able to see this bc they will change
		// monthlyPayment: {
		// 	title: 'Monthly payment',
		// 	rank: 4,
		// },
		// loanAmount: {
		// 	title: 'Amount financed',
		// 	rank: 5,
		// },
		// totalPayment: {
		// 	title: "Total amount you'll pay over time",
		// 	rank: 6,
		// },
		// downPayment: {
		// 	title: 'Down payment (i.e. the amount you pay up front)',
		// 	rank: 7,
		// },
	},
	// otherLenderInfoFieldMap: {
	// 	name: {
	// 		title: 'Name of lender',
	// 		rank: 1,
	// 	},
	// 	apr: {
	// 		title: 'APR of your loan (in percent)',
	// 		rank: 2,
	// 	},
	// 	term: {
	// 		title: 'Loan term (number of months)',
	// 		rank: 3,
	// 	},
	// },
	registrantDetailsFieldMapNoMail: regDetails,
	registrantDetailsFieldMapWithMail: {
		...regDetails,
		mailingAddress: {
			addressLineOne: {
				title: 'Mailing address line 1',
				rank: 19,
			},
			addressLineTwo: {
				title: 'Mailing address line 2',
				rank: 20,
			},
			city: {
				title: 'Mailing address city',
				rank: 21,
			},
			county: {
				title: 'Mailing address county',
				rank: 22,
			},
			state: {
				title: 'Mailing address state',
				rank: 23,
			},
			zip: {
				title: 'Mailing address zip',
				rank: 24,
			},
		},
	},
	applicantDetailsFieldMap: {
		firstName: {
			title: 'First name',
			rank: 1,
		},
		lastName: {
			title: 'Last name',
			rank: 2,
		},
		dob: {
			title: 'Date of birth',
			rank: 3,
		},
		ssn: {
			title: 'SSN',
			rank: 4,
		},
		email: {
			title: 'Email',
			rank: 5,
		},
		phoneNumber: {
			title: 'Phone number',
			rank: 6,
		},
		addressLineOne: {
			title: 'Address line 1',
			rank: 7,
		},
		addressLineTwo: {
			title: 'Address line 2',
			rank: 8,
		},
		city: {
			title: 'Address city',
			rank: 9,
		},
		state: {
			title: 'Address state',
			rank: 10,
		},
		zip: {
			title: 'Address zip',
			rank: 11,
		},
		maritalStatus: {
			title: 'Marital status',
			rank: 12,
		},
		housingOwnership: {
			title: 'Housing ownership',
			rank: 13,
		},
		homeMoveInDate: {
			title: 'Home move-in date',
			rank: 14,
		},
		monthlyAmt: {
			title: 'Monthly payment',
			rank: 15,
		},
	},
	applicantEmploymentFieldMap: {
		employmentStatus: {
			title: 'Employment status',
			rank: 1,
		},
		occupation: {
			title: 'Occupation',
			rank: 2,
		},
		monthlyGrossIncome: {
			title: 'Monthly gross income',
			rank: 3,
		},
		jobStartDate: {
			title: 'Job start date',
			rank: 4,
		},
		employerName: {
			title: 'Employer name',
			rank: 5,
		},
		addressLineOne: {
			title: 'Address line 1',
			rank: 6,
		},
		addressLineTwo: {
			title: 'Address line 2',
			rank: 7,
		},
		city: {
			title: 'Address city',
			rank: 8,
		},
		state: {
			title: 'Address state',
			rank: 9,
		},
		zip: {
			title: 'Address zip',
			rank: 10,
		},
		otherIncomes: {
			title: 'Total in other income (monthly)',
			rank: 11,
		},
	},
	// coApplicantDetailsFieldMap: {
	// 	firstName: 'First name',
	// 	lastName: 'Last name',
	// 	dob: 'Date of birth',
	// 	ssn: 'SSN',
	// 	phoneNumber: 'Phone number',
	// 	addressLineOne: 'Address line 1',
	// 	addressLineTwo: 'Address line 2',
	// 	city: 'Address city',
	// 	state: 'Address state',
	// 	zip: 'Address zip',
	// 	maritalStatus: 'Marital status',
	// 	hasCoApplicant: 'Has co-applicant',
	// },
	// coApplicantEmploymentFieldMap: {
	// 	employerName: 'Employer name',
	// 	addressLineOne: 'Address line 1',
	// 	addressLineTwo: 'Address line 2',
	// 	city: 'Address city',
	// 	state: 'Address state',
	// 	zip: 'Address zip',
	// 	jobStartDate: 'Job start date',
	// 	occupation: 'Occupation',
	// 	monthylGrossIncome: 'Monthly gross income',
	// },
	pickUpDetailsFieldMap: {
		pickUpLocationName: {
			title: 'Pick up location name',
			rank: 1,
		},
		addressLineOne: {
			title: 'Pick up location street',
			rank: 2,
		},
		addressLineTwo: {
			title: 'Pick up location apt/suite',
			rank: 3,
		},
		city: {
			title: 'Pick up location city',
			rank: 4,
		},
		state: {
			title: 'Pick up location state',
			rank: 5,
		},
		zip: {
			title: 'Pick up location zip',
			rank: 6,
		},
	},
	deliveryDetailsFieldMap: {
		addressLineOne: {
			title: 'Address line 1',
			rank: 1,
		},
		addressLineTwo: {
			title: 'Address line 2',
			rank: 2,
		},
		city: {
			title: 'Address city',
			rank: 3,
		},
		state: {
			title: 'Address state',
			rank: 4,
		},
		zip: {
			title: 'Address zip',
			rank: 5,
		},
		deliveryInstructions: {
			title: 'Delivery instructions',
			rank: 6,
		},
		poc: {
			title: 'Delivery point of contact',
			rank: 7,
		},
		pocName: {
			title: 'Point of contact name',
			rank: 8,
		},
		pocNumber: {
			title: 'Point of contact number',
			rank: 9,
		},
	},
};

// Credit application lender decision types
export const creditAppDecisionTypes = {
	accepted: 'accepted',
	rejected: 'rejected',
	needMoreInfo: 'needMoreInfo',
	counterOffer: 'counterOffer',
};

// Pre-qualification states
export const PREQUAL_STATUSES = {
	NOT_SUPPORTED: 'NOT_SUPPORTED', // During the period where pre-qual only applies to FRF, this status might be helpful if none of the models in the cart would be financed by FRF
	IN_PROGRESS: 'IN_PROGRESS',
	NOT_STARTED: 'NOT_STARTED', // default
	APPROVED: 'APPROVED',
	DENIED: 'DENIED',
};

export const PREQUAL_OUTCOMES = {
	NO_APPLICATION: 'NO_APPLICATION',
	APPROVED: 'APPROVED',
	DENIED: 'DENIED',
};

// The different types of decisions a buyer can make on a finance offer from the lender. This will grow with time.
export const financeOfferBuyerDecisionTypes = {
	accepted: 'accepted',
	declined: 'declined',
};

// Vehicle stages
export const vehicleStageToNum = {
	signOff: 1,
	provisionParts: 2,
	production: 3,
	waitingToShip: 4,
	shipped: 5,
};

export const buyerPaymentOptions = {
	nativeFinancing: 'nativeFinancing',
	otherFinancing: 'otherFinancing',
	cash: 'cash',
};

export const PICK_UP_LOCATION_FILTERS = {
	ALL: {
		id: 'ALL',
		name: 'All',
	},
	OEM_AND_DEALERS: {
		id: 'OEM_AND_DEALERS',
		name: 'OEM and dealers',
	},
};

export const EKHO_PICK_UP_LOCATION_ID = 'ekho-dealer-pickup';

export const MAX_INSURANCE_VERIFICATION_ATTEMPTS = 3;

export const rejectedCreditDecision = {
	decision: 'rejected',
	apr: 0.05,
	downPayment: 500,
	loanAmount: 30200,
	loanTerm: 24,
	monthlyPayment: 400,
	buyerOfferDecision: null,
};

export const needMoreInfoCreditDecision = {
	decision: 'needMoreInfo',
	apr: 0.05,
	downPayment: 500,
	loanAmount: 30200,
	loanTerm: 24,
	monthlyPayment: 400,
	buyerOfferDecision: null,
};

export const acceptedCreditDecision = {
	decision: 'accepted',
	apr: 0.05,
	downPayment: 500,
	loanAmount: 30200,
	loanTerm: 24,
	monthlyPayment: 400,
	buyerOfferDecision: null,
};

// The target down payment we set for the Pay Sooner option
export const paySoonerDownPaymentPerc = 0.05;
// The target down payment we set for the Pay Less Now option
export const payLessNowDownPaymentPerc = 0.2;

/* END SECTION CONSTS */

/* TEXT CONSTS */
export const ITEM_ID_SEPARATOR = '------';
/* END TEXT CONSTS */

/* FINANCING CONSTS */
export const INITIAL_TERM = 84.0;
export const INITIAL_DOWN_PAYMENT_SUBTRACTION = 1500;
export const MIN_LOAN_AMOUNT = 1500;
/* END FINANCING CONSTS */

/* PAYMENT OPTIONS */
export const PAYMENT_CHOICES = {
	CHECKOUT_FULL_PAYMENT: 'CHECKOUT_FULL_PAYMENT',
	LOAN_DOWNPAYMENT: 'LOAN_DOWNPAYMENT', // Value when financing is selected (even if zero downpayment)
	STANDARD_RESERVATION: 'STANDARD_RESERVATION',
	PREORDER_RESERVATION: 'PREORDER_RESERVATION',
	PREORDER_VIP_RESERVATION: 'PREORDER_VIP_RESERVATION',
	KLARNA_CHECKOUT_FULL_PAYMENT: 'KLARNA_CHECKOUT_FULL_PAYMENT',
};

export const PAYMENT_CHOICE_TITLES = {
	CHECKOUT_FULL_PAYMENT: 'Pay by card',
	LOAN_DOWNPAYMENT: 'Finance with Ekho',
	STANDARD_RESERVATION: 'Reserve',
	PREORDER_RESERVATION: 'Reserve',
	PREORDER_VIP_RESERVATION: 'Reserve (VIP)',
	KLARNA_CHECKOUT_FULL_PAYMENT: 'Finance with Klarna',
};

// Only used to save the payment selection in the paymentInfo on the purchasedVehicle object. We are phasing out the use of this enum.
export const PAYMENT_SELECTION_CHOICES = {
	KLARNA_CHECKOUT_FULL_PAYMENT: 'klarnaCheckoutFullPayment',
	CHECKOUT_FULL_PAYMENT: 'checkoutFullPayment',
	FINANCING: 'nativeFinancing',
	CASH_RESERVATION: 'cash',
};

// Map to convert payment choices to payment selection choices
export const PAYMENT_CHOICE_TO_SELECTION_MAP = {
	[PAYMENT_CHOICES.CHECKOUT_FULL_PAYMENT]: PAYMENT_SELECTION_CHOICES.CHECKOUT_FULL_PAYMENT,
	[PAYMENT_CHOICES.LOAN_DOWNPAYMENT]: PAYMENT_SELECTION_CHOICES.FINANCING,
	[PAYMENT_CHOICES.STANDARD_RESERVATION]: PAYMENT_SELECTION_CHOICES.CASH_RESERVATION,
	[PAYMENT_CHOICES.KLARNA_CHECKOUT_FULL_PAYMENT]:
		PAYMENT_SELECTION_CHOICES.KLARNA_CHECKOUT_FULL_PAYMENT,
};

export const MODULES = {
	CONFIGURATOR: 'CONFIGURATOR',
	PAYMENT: 'PAYMENT',
};

export const CHECKOUT_SECTIONS = {
	CONFIGURATOR: 'CONFIGURATOR', // NOT USED YET => CONFIGURATOR IS CURRENTLY IN ORDER_DETAILS SECTION
	ORDER_DETAILS: 'ORDER_DETAILS',
	CREDIT_APP: 'CREDIT_APP',
};

export const PAYMENT_CONFIG_KEYS = {
	STANDARD_RESERVATION: 'STANDARD_RESERVATION',
	CHECKOUT_FULL_PAYMENT: 'CHECKOUT_FULL_PAYMENT',
	LOAN_DOWNPAYMENT: 'LOAN_DOWNPAYMENT',
	PREORDER_RESERVATION: 'PREORDER_RESERVATION',
	PREORDER_VIP_RESERVATION: 'PREORDER_VIP_RESERVATION',
	EARLY_REMAINING_BALANCE: 'EARLY_REMAINING_BALANCE',
	LATE_REMAINING_BALANCE: 'LATE_REMAINING_BALANCE',
	LOAN_ADDITIONAL_DOWN: 'LOAN_ADDITIONAL_DOWN',
	KLARNA_CHECKOUT_FULL_PAYMENT: 'KLARNA_CHECKOUT_FULL_PAYMENT',
};

export const DISCOUNT_TYPES = {
	PERCENTAGE_DISCOUNT: 'PERCENTAGE_DISCOUNT',
	FIXED_AMOUNT_DISCOUNT: 'FIXED_AMOUNT_DISCOUNT',
	SHIPPING_DISCOUNT: 'SHIPPING_DISCOUNT',
};

export const DISCOUNT_CATEGORIES = {
	REENGAGEMENT_DISCOUNT: 'REENGAGEMENT_DISCOUNT',
	DISCOUNT_CAMPAIGN: 'DISCOUNT_CAMPAIGN',
	CUSTOM_DISCOUNT: 'CUSTOM_DISCOUNT',
	FULL_PAYMENT_DISCOUNT: 'FULL_PAYMENT_DISCOUNT',
};

export const DISCOUNT_APPLICABILTY_KEYWORDS = {
	ALL: 'ALL',
	MAIN_VEHICLE: 'MAIN_VEHICLE',
};

export const DELIVERY_CHOICES = {
	SHIP: 'SHIP',
	PICK_UP: 'PICK_UP',
};

// Defines when we do web vs mobile rendering
export const STACKING_BREAKPOINT = 1150;
export const CHECKOUT_INFO_MAX_WIDTH = 650;

// id used to identify the scrolling container we need to attach a listener to in the GooglePlacesAddress component
export const SCROLL_CONTAINER_ID = 'ekho-checkout-scrolling-container';

// Interim solution for naive guess at registration fees = 400 (average state fees) + 165 (VT) + variable fee (added later)
export const STATE_AGNOSTIC_BASE_REGISTRATION_FEES = 565;
export const STATE_AGNOSTIC_BASE_TITLING_FEES = 565;
export const REQUIRED_DOWN_PAYMENT_PERCENTAGE = 0.15;

// Stripe paymentInput states
export const PAYMENT_INPUT_STATES = {
	EMPTY: 'EMPTY',
	INCOMPLETE: 'INCOMPLETE',
	COMPLETE: 'COMPLETE',
};

// The payment intent types that originate from checkout
export const CHECKOUT_PAYMENT_TYPES = [
	PAYMENT_TYPES.CHECKOUT_FULL_PAYMENT,
	PAYMENT_TYPES.STANDARD_RESERVATION,
	PAYMENT_TYPES.LOAN_DOWNPAYMENT,
];

export const PREORDER_PAYMENT_TYPES = [
	PAYMENT_TYPES.PREORDER_RESERVATION,
	PAYMENT_TYPES.PREORDER_VIP_RESERVATION,
];

export const BUYER_PORTAL_PAYMENT_TYPES = [
	PAYMENT_TYPES.EARLY_REMAINING_BALANCE,
	PAYMENT_TYPES.LATE_REMAINING_BALANCE,
	PAYMENT_TYPES.LOAN_ADDITIONAL_DOWN,
];

// The 3 different umbrella descriptions of all payment intent types
export const PAYMENT_DESCRIPTION_TYPES = {
	PREORDER_PAYMENT: {
		description: 'Preorder Payment',
		paymentTypes: [PAYMENT_TYPES.PREORDER_RESERVATION, PAYMENT_TYPES.PREORDER_VIP_RESERVATION],
	},
	RESERVATION_PAYMENT: {
		description: 'Reservation Payment',
		paymentTypes: [PAYMENT_TYPES.STANDARD_RESERVATION, PAYMENT_TYPES.LOAN_DOWNPAYMENT],
	},
	REMAINING_BALANCE_PAYMENT: {
		description: 'Remaining Balance Payment',
		paymentTypes: [
			PAYMENT_TYPES.EARLY_REMAINING_BALANCE,
			PAYMENT_TYPES.LATE_REMAINING_BALANCE,
			PAYMENT_TYPES.CHECKOUT_FULL_PAYMENT,
		],
	},
	LOAN_ADDITIONAL_DOWN: {
		description: 'Loan Additional Down Payment',
		paymentTypes: [PAYMENT_TYPES.LOAN_ADDITIONAL_DOWN],
	},
};

export const STRIPE_PAYMENT_METHOD_TYPES = {
	CARD: 'card',
	AFFIRM: 'affirm',
	KLARNA: 'klarna',
	US_BANK_ACCOUNT: 'us_bank_account',
};
